<template>
    <div class="search-wrap">
        <div class="top-search">
            <van-icon name="arrow-left" size="20px" color="#535c66" @click="router.go(-1)" />
            <div class="search-input">
                <van-search
                        id="searchRef"
                        v-model="search"
                        @update:model-value="handleUpdate"
                        placeholder="请输入搜索的银行"
                        clearable
                        shape="round"
                />
            </div>
        </div>

        <div class="search-list" :class="{'loading': loading }" id="searchList">
            <van-cell-group v-if="searchResults.length > 0">
                <van-cell
                        v-for="(item, index) in searchResults"
                        :key="index" :title="item.name"
                        @click="handleCurrent(item)"
                />
            </van-cell-group>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, onBeforeUnmount } from 'vue'
import { useRouter } from "vue-router"
import { Search } from 'vant'
import { getBankList } from '@/api/api'

export default {
  setup () {
    const router = useRouter()

    // 获取银行列表数据
    const bankActions = ref([])
    const searchResults = ref([])

    const loading = ref(false)

    const handleGetBankList = () => {
      loading.value = true
      getBankList().then(res => {
        if(res.result == 200000) {
          Object.getOwnPropertyNames(res.data).forEach(function(key){
            let obj = {}
            obj.name = res.data[key]
            obj.id = key
            bankActions.value.push(obj)
            searchResults.value.push(obj)
            loading.value = false
          })
        } else {
          loading.value = false
          bankActions.value = []
          searchResults.value = []
        }
      })
    }

    onMounted(() => {
      handleGetBankList()
      // window.addEventListener('touchmove', closeKeyboardOnScroll)
    })

    onBeforeUnmount(() => {
      // window.removeEventListener('touchmove', closeKeyboardOnScroll)
    })

    // const closeKeyboardOnScroll = () => {
    //   if (document.hasFocus) {
    //     const inputId = document.getElementById('searchRef')
    //     inputId.blur()
    //     document.activeElement.blur()
    //   }
    // }

    const search = ref('')

    //  银行搜索执行
    function fuzzySearch(query) {
      if (!query) {
        return bankActions.value
      }
      query = query.toLowerCase()
      const results = bankActions.value.filter(item => item.name.toLowerCase().includes(query))
      return results
    }

    //  选择银行
    const handleCurrent = (record) => {
      router.replace({
        path: '/addPayment',
        query: {
          search: JSON.stringify(record),
          currentIndex: 3
        }
      })
    }

    const handleUpdate = (value) => {
      if (value === '') {
        searchResults.value = [...bankActions.value]
      } else {
        const data = fuzzySearch(value)
        searchResults.value = data
      }
    }

    return {
      router,
      search,
      bankActions,
      handleCurrent,
      handleUpdate,
      searchResults,
      loading
    }
  }
}
</script>

<style scoped lang="less">
.search-wrap {
    .top-search {
        display: flex;
        align-items: center;
        width: 100%;
        height: 56px;
        /deep/ .van-search__content {
            background: var(--orderSearchNumInputBgColor);
        }
        .search-input {
            width: 94%;
            /deep/ .van-field__control {
                color: var(--van-field-input-text-color);
            }
        }
    }

    /deep/ .van-cell {
    }
    .search-list {
        min-height: 400px;
        position: relative;
        &:empty:after {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            content: 'No Data';
            color: #535c66;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &.loading {
            &:empty:after {
                content: '加载中...';
            }
        }
    }
}
</style>
